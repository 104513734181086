<template>
    <div class="d-flex flex-column min-vh-100">
        <Header :showLogout="true" :profile="profile"/>
        <div class="container">
            <div class="row">
                <div class="col-3">
                    <div class="list-group">
                        <router-link to="/profile" class="list-group-item"><i class="fa fa-book"></i> <span>Mis cursos</span></router-link>
                        <router-link to="/profile/payments" class="list-group-item active"><i class="fa fa-credit-card"></i> <span>Pagos</span></router-link>
                        <router-link to="/profile/me" class="list-group-item"><i class="fa fa-key"></i> <span>Mis datos</span></router-link>
                    </div>
                </div>
                <div class="col-9">
                    <h2>Pagos / {{invoice.id}}</h2>

                    <div class="">
                        <table class="table table-fluid">
                            <thead>
                                <tr>
                                    <th>Curso</th>
                                    <th>Nombre del curso</th>
                                </tr>
                            </thead>

                        </table>
                        <div v-if="invoice.payment_method=='card'">
                            Pago con tarjeta
                        </div>
                        <div v-else-if="invoice.payment_method=='oxxo' && invoice.payment_data != null">
                             <div class="container" id="app" style="width:600px">
                                <div class="row">
                                    <div class="col-xs-12">
                                        <h3>Paga en Oxxo</h3>
                                        <div class="card mt-3">
                                            <div class="card-body">
                                                <h4 class="text-end">${{invoice.payment_data.amount}}</h4>
                                                <img :src="invoice.payment_data.barcode_url" width="100%" style="height:80px">
                                                REFERENCIA
                                                <div style="padding:10px; background:#CCC; text-align:center; font-size:24px">{{invoice.payment_data.reference}}</div>
                                                <b>Paga antes de:</b> {{invoice.payment_data.expire_date}} a las  {{invoice.payment_data.expire_time}}
                                                <p style="margin-top:20px">INSTRUCCIONES</p>
                                                <ol>
                                                    <li>Acude a la <b>tienda OXXO</b> más cercana.</li>
                                                    <li>Indica en la caja que quieres realizar un pago de OXXOPay.</li>
                                                    <li>Entrega al cajero el número de referencia o el código de barras.</li>
                                                    <li>Realiza el pago correspondiente.</li>
                                                    <li>Al confirmar tu pago, el cajero te entregará tu comprobante, verifica que esté correcto y consérvalo.</li>
                                                    <li>Recibirás un correo de confirmación de pago aplicado</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <Footer />
    </div>
</template>
<style scoped>
 .list-group {

     margin:auto;
     float:left;
     padding-top:20px;
    }
    .card-horizontal {
    display: flex;
    flex: 1 1 auto;
}
</style>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import axios from 'axios';

export default {
  components:{
      Header,
      Footer
  },
  name: 'ProfilePaymentDetails',
  data() {
      return {
          invoice: {},
          profile: this.$store.getters.getUser
      }
  },
  mounted(){
      this.loadInvoice();
  },
  methods: {
      loadInvoice() {
          axios.get(`${process.env.VUE_APP_APIURL}users/invoices/${this.profile.id}/${this.$route.params.invoiceId}`)
          .then((response)=>{
              //alert(JSON.stringify(response.data));
              this.invoice =  response.data.data
              this.invoice.payment_data = JSON.parse( this.invoice.payment_data)
              console.log(this.invoice);
          }).catch((error)=>{
              alert(error);
          })
      }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg-darkBlue{
    background: #152F44
}
.btn-black{ background:#000; color:#FFF; font-size:14px; border:1px solid #979797;}
.btn-black:hover{color:#FFF; background:#333232}
.btn-golden{ background:#AA8D5D; color:#FFF; font-size:14px; border:1px solid #9B7676;}
.btn-golden:hover{color:#FFF; background:#C4A167}

.navbar-light .navbar-nav .nav-link{ color:#FFF}
.navbar-light .navbar-nav .nav-link:hover{ background:#C4A167}
.btn-outline-white{
    border:1px solid #FFF;
    color:#FFF
}
.carousel-caption {top:30%}
.carousel-caption h5{ margin-bottom:30px}
.section{margin:60px 0}
footer{margin-top:60px}
.subheader{ background:#D1C1A7; color:#FFF; display: flex;
  align-items: center;
  justify-content: center; height:250px}
  .course:hover{
      opacity:.8
  }
</style>
